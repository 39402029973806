import React from "react";
import { UserWithoutBookingsFragment } from "../generated/graphql";

export const UserInfo: React.FC<{
  user: UserWithoutBookingsFragment;
  logout: () => void;
}> = ({ user, logout }) => {
  return (
    <button
      className="block flex-shrink-0 group text-left"
      onClick={() => logout()}
    >
      <div className="flex items-center">
        <div>
          <div className="inline-block h-9 w-9">
            <img className="h-full w-auto" src={user.imageUrl} alt="" />
          </div>
        </div>
        <div className="ml-3">
          <p className="text-sm font-semibold text-gray-700 group-hover:text-gray-900">
            {user.name}
          </p>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            Abmelden
          </p>
        </div>
      </div>
    </button>
  );
};

export default UserInfo;
