import React from "react";
import { useDeleteBookingMutation, useGetMeQuery } from "../generated/graphql";
import { TrashIcon } from "@heroicons/react/solid";
import { classNames } from "../classNames";
import { format, isBefore, isSameDay, parseISO } from "date-fns";
import { de } from "date-fns/locale";

export const ListBookings: React.FC<{}> = ({}) => {
  const { data, loading, error, refetch } = useGetMeQuery({
    fetchPolicy: "network-only",
  });
  const [deleteBooking] = useDeleteBookingMutation();
  if (loading) return <p className="px-3">Lade...</p>;
  return (
    <div>
      <p className="px-3 my-4">
        {data?.me.bookings.length === 0
          ? "Aktuell sind keine Buchungen vorhanden."
          : "Hier sehen Sie Ihre aktuellen Buchungen. Bereits vergangene Buchungen werden nicht angezeigt. Gerade laufende Buchung werden rot hinterlegt."}
      </p>
      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:mx-3 mt-4">
        <ul className="divide-y divide-gray-200">
          {data?.me.bookings.map((booking) => {
            const startDate = parseISO(booking.slot.start);
            const endDate = parseISO(booking.slot.end);
            const isPast = isBefore(endDate, new Date());
            const isNow = !isPast && isBefore(startDate, new Date());
            const sameDay = isSameDay(startDate, endDate);
            return (
              <li key={booking.id}>
                <div
                  className={classNames(
                    "block",
                    isPast
                      ? "bg-gray-300 opacity-60"
                      : isNow
                      ? "bg-red-50"
                      : "hover:bg-gray-50"
                  )}
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      {format(startDate, "eeee dd.MM.yyyy HH:mm", {
                        locale: de,
                      })}{" "}
                      Uhr bis{" "}
                      {format(
                        endDate,
                        `${sameDay ? "" : "eeee dd.MM.yyyy "}HH:mm`,
                        { locale: de }
                      )}{" "}
                      Uhr
                    </div>
                    <div>
                      {!isPast && (
                        <>
                          <button
                            className="text-red-dark font-bold hover:text-red-900 flex items-center"
                            onClick={() => {
                              deleteBooking({
                                variables: { id: booking.id },
                              }).finally(() => refetch());
                            }}
                          >
                            <TrashIcon
                              className="h-5 w-5 mr-1"
                              aria-hidden="true"
                            />
                            Stornieren
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
