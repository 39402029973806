import React from "react";
import { UserWithoutBookingsFragment } from "../generated/graphql";
import logo from "../logo.png";
import Tabs from "./Tabs";
import { UserInfo } from "./UserInfo";

export const Header: React.FC<{
  user?: UserWithoutBookingsFragment;
  logout: () => void;
}> = ({ user, logout }) => (
  <header className="container mx-auto max-w-screen-sm px-3 pt-4">
    <a href="https://www.feuerwehragentur.de/" target="_blank" rel="noreferrer">
      <img className="w-1/2 mx-auto mb-12" src={logo} alt="FeuerwehrAgentur" />
    </a>
    <div className="flex justify-between mb-5 flex-col sm:flex-row sm:items-center space-y-3">
      <h1 className="text-4xl tracking-tight">Raumbuchung</h1>
      {user && <UserInfo user={user} logout={logout} />}
    </div>

    {user && (
      <Tabs
        tabs={[
          { name: "Meine Buchungen", path: "/buchungen" },
          { name: "Neue Buchung", path: "/buchen" },
        ]}
      />
    )}
  </header>
);

export default Header;
