import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Booking = {
  __typename?: 'Booking';
  id: Scalars['ID'];
  slot: Slot;
  user: User;
};


export type LoginResult = {
  __typename?: 'LoginResult';
  user: User;
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResult;
  createBooking: Booking;
  deleteBooking: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
};


export type MutationCreateBookingArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type MutationDeleteBookingArgs = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  me: User;
  users: Array<User>;
  blockedSlots: Array<Slot>;
};


export type QueryBlockedSlotsArgs = {
  day: Scalars['DateTime'];
};

export type Slot = {
  __typename?: 'Slot';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  imageUrl: Scalars['String'];
  bookings: Array<Booking>;
  isAdmin: Scalars['Boolean'];
};

export type UserWithoutBookingsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'imageUrl' | 'isAdmin'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & { bookings: Array<(
    { __typename?: 'Booking' }
    & BookingWithoutUserFragment
  )> }
  & UserWithoutBookingsFragment
);

export type BookingWithoutUserFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id'>
  & { slot: (
    { __typename?: 'Slot' }
    & SlotFragment
  ) }
);

export type BookingFragment = (
  { __typename?: 'Booking' }
  & { user: (
    { __typename?: 'User' }
    & UserWithoutBookingsFragment
  ) }
  & BookingWithoutUserFragment
);

export type LoginResultFragment = (
  { __typename?: 'LoginResult' }
  & Pick<LoginResult, 'token'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type SlotFragment = (
  { __typename?: 'Slot' }
  & Pick<Slot, 'start' | 'end'>
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & LoginResultFragment
  ) }
);

export type CreateBookingMutationVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type CreateBookingMutation = (
  { __typename?: 'Mutation' }
  & { createBooking: (
    { __typename?: 'Booking' }
    & BookingFragment
  ) }
);

export type DeleteBookingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBooking'>
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & UserWithoutBookingsFragment
  )> }
);

export type GetBlockedSlotsQueryVariables = Exact<{
  day: Scalars['DateTime'];
}>;


export type GetBlockedSlotsQuery = (
  { __typename?: 'Query' }
  & { blockedSlots: Array<(
    { __typename?: 'Slot' }
    & SlotFragment
  )> }
);

export const SlotFragmentDoc = gql`
    fragment Slot on Slot {
  start
  end
}
    `;
export const BookingWithoutUserFragmentDoc = gql`
    fragment BookingWithoutUser on Booking {
  id
  slot {
    ...Slot
  }
}
    ${SlotFragmentDoc}`;
export const UserWithoutBookingsFragmentDoc = gql`
    fragment UserWithoutBookings on User {
  id
  name
  imageUrl
  isAdmin
}
    `;
export const BookingFragmentDoc = gql`
    fragment Booking on Booking {
  ...BookingWithoutUser
  user {
    ...UserWithoutBookings
  }
}
    ${BookingWithoutUserFragmentDoc}
${UserWithoutBookingsFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  ...UserWithoutBookings
  bookings {
    ...BookingWithoutUser
  }
}
    ${UserWithoutBookingsFragmentDoc}
${BookingWithoutUserFragmentDoc}`;
export const LoginResultFragmentDoc = gql`
    fragment LoginResult on LoginResult {
  user {
    ...User
  }
  token
}
    ${UserFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($password: String!) {
  login(password: $password) {
    ...LoginResult
  }
}
    ${LoginResultFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($start: DateTime!, $end: DateTime!) {
  createBooking(start: $start, end: $end) {
    ...Booking
  }
}
    ${BookingFragmentDoc}`;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const DeleteBookingDocument = gql`
    mutation DeleteBooking($id: ID!) {
  deleteBooking(id: $id)
}
    `;
export type DeleteBookingMutationFn = Apollo.MutationFunction<DeleteBookingMutation, DeleteBookingMutationVariables>;

/**
 * __useDeleteBookingMutation__
 *
 * To run a mutation, you first call `useDeleteBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingMutation, { data, loading, error }] = useDeleteBookingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingMutation, DeleteBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingMutation, DeleteBookingMutationVariables>(DeleteBookingDocument, options);
      }
export type DeleteBookingMutationHookResult = ReturnType<typeof useDeleteBookingMutation>;
export type DeleteBookingMutationResult = Apollo.MutationResult<DeleteBookingMutation>;
export type DeleteBookingMutationOptions = Apollo.BaseMutationOptions<DeleteBookingMutation, DeleteBookingMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    ...UserWithoutBookings
  }
}
    ${UserWithoutBookingsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetBlockedSlotsDocument = gql`
    query GetBlockedSlots($day: DateTime!) {
  blockedSlots(day: $day) {
    ...Slot
  }
}
    ${SlotFragmentDoc}`;

/**
 * __useGetBlockedSlotsQuery__
 *
 * To run a query within a React component, call `useGetBlockedSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockedSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockedSlotsQuery({
 *   variables: {
 *      day: // value for 'day'
 *   },
 * });
 */
export function useGetBlockedSlotsQuery(baseOptions: Apollo.QueryHookOptions<GetBlockedSlotsQuery, GetBlockedSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlockedSlotsQuery, GetBlockedSlotsQueryVariables>(GetBlockedSlotsDocument, options);
      }
export function useGetBlockedSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlockedSlotsQuery, GetBlockedSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlockedSlotsQuery, GetBlockedSlotsQueryVariables>(GetBlockedSlotsDocument, options);
        }
export type GetBlockedSlotsQueryHookResult = ReturnType<typeof useGetBlockedSlotsQuery>;
export type GetBlockedSlotsLazyQueryHookResult = ReturnType<typeof useGetBlockedSlotsLazyQuery>;
export type GetBlockedSlotsQueryResult = Apollo.QueryResult<GetBlockedSlotsQuery, GetBlockedSlotsQueryVariables>;