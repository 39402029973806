import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient";
import { CreateBooking } from "./components/CreateBooking";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { Login } from "./components/Login";
import { Layout } from "./components/Layout";
import React, { useState } from "react";
import { UserWithoutBookingsFragment } from "./generated/graphql";
import {
  getPersistedLoginResult,
  localStorageKey,
  persistLoginResult,
} from "./localStorage";
import { ListBookings } from "./components/ListBookings";

const PrivateRoute: React.FC<
  React.PropsWithChildren<{ user?: UserWithoutBookingsFragment }> & RouteProps
> = ({ children, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function App() {
  const persistedLoginResult = getPersistedLoginResult();
  const [user, setUser] = useState<UserWithoutBookingsFragment | undefined>(
    persistedLoginResult?.user
  );

  return (
    <ApolloProvider client={client}>
      <Router>
        <Layout
          user={user}
          logout={() => {
            localStorage.removeItem(localStorageKey);
            setUser(undefined);
          }}
        >
          <Switch>
            <Route exact path="/">
              <h2 className="text-2xl px-3">Anmelden</h2>
              <Login
                onLogin={(rs) => {
                  persistLoginResult(rs);
                  setUser(rs.user);
                }}
              />
            </Route>
            <PrivateRoute path="/buchen" user={user}>
              <h2 className="text-2xl px-3">Raum buchen</h2>
              <CreateBooking />
            </PrivateRoute>
            <PrivateRoute path="/buchungen" user={user}>
              <h2 className="text-2xl px-3">Aktuelle Buchungen</h2>
              <ListBookings />
            </PrivateRoute>
          </Switch>
        </Layout>
      </Router>
    </ApolloProvider>
  );
}

export default App;
