import React from "react";

export const Footer: React.FC<{}> = ({}) => {
  return (
    <footer className="bg-red-dark text-sm text-white py-8">
      <div className="max-w-screen-sm mx-auto px-3 space-x-2">
        <a
          className="hover:underline"
          href="https://www.feuerwehragentur.de/impressum"
          target="_blank"
          rel="noreferrer"
        >
          Impressum
        </a>
        <a
          className="hover:underline"
          href="https://www.feuerwehragentur.de/"
          target="_blank"
          rel="noreferrer"
        >
          Mehr zur FeuerwehrAgentur
        </a>
      </div>
    </footer>
  );
};

export default Footer;
