import { LoginResultFragment } from "./generated/graphql";

export const localStorageKey = "rbLRs";
export function persistLoginResult(loginResult: LoginResultFragment): void {
  localStorage.setItem(localStorageKey, JSON.stringify(loginResult));
}
export function getPersistedLoginResult(): LoginResultFragment | undefined {
  const data = localStorage.getItem(localStorageKey);
  if (!data)
    return undefined;
  return JSON.parse(data);
}
