import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginResultFragment, useLoginMutation } from "../generated/graphql";

export const Login: React.FC<{ onLogin: (loginResult: LoginResultFragment) => void }> = ({
  onLogin: setLoginResult,
}) => {
  let history = useHistory();
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [login] = useLoginMutation({ variables: { password } });

  return (
    <>
      <div className="bg-white overflow-hidden shadow mt-4 sm:rounded-lg sm:max-w-lg mx-auto sm:mt-8">
        <div className="px-4 py-5">
          <p className="mb-4">Bitte melden Sie sich mit Ihrem Kennwort an.</p>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Kennwort
            </label>
            <div className="mt-1">
              <input
                type="password"
                name="password"
                id="password"
                className="shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Kennwort eingeben"
                aria-describedby="password-description"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="password-description">
              Das Kennwort haben Sie in einer E-Mail von dem Absender{" "}
              <i>info@feuerwehragentur.de</i> erhalten.
            </p>
            <button
              type="submit"
              className="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => {
                login()
                  .then((rs) => {
                    if (!rs.data?.login)
                      throw new Error("Anmeldung nicht erfolgreich");
                    setLoginResult(rs.data.login);
                    history.push("/buchen");
                  })
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              Anmelden
            </button>
            {error && <p className="mt-2 text-red-dark">{error}</p>}
          </div>
        </div>
      </div>
    </>
  );
};
