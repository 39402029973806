import { Link, useHistory, useLocation } from "react-router-dom";
import { classNames } from "../classNames";

export default function Tabs({
  tabs,
}: {
  tabs: { name: string; path: string }[];
}) {
  const { pathname } = useLocation();
  const history = useHistory();
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Tab auswählen
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
          onChange={(e) =>
            history.push(
              tabs.find((tab) => tab.name === e.target.value)?.path || "/"
            )
          }
          defaultValue={tabs.find((tab) => tab.path === pathname)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.path}
              className={classNames(
                pathname === tab.path
                  ? "bg-red-100 text-red-dark"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
              aria-current={pathname === tab.path ? "page" : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
}
