import {
  areIntervalsOverlapping,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfToday,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  set,
  startOfDay,
  startOfToday,
} from "date-fns";
import { de } from "date-fns/locale";
import React, { useEffect, useMemo, useState } from "react";
import TimeRange from "react-timeline-range-slider";
import {
  Booking,
  BookingFragment,
  useCreateBookingMutation,
  useGetBlockedSlotsQuery,
} from "../generated/graphql";
import Calendar from "react-calendar";
import "../calendar.scss";
import { MAX_BOOKING_LENGTH } from "../constants";
import "../timeline-range-slider.scss";
import { classNames } from "../classNames";

function useBlockedSlots(day: Date) {
  const { data, loading, error,refetch } = useGetBlockedSlotsQuery({
    variables: { day },
    fetchPolicy: "network-only",
  });
  const blockedSlots = useMemo(
    () =>
      data?.blockedSlots.map((slot) => ({
        start: parseISO(slot.start),
        end: parseISO(slot.end),
      })) || [],
    [data]
  );

  return {
    blockedSlots,
    loading,
    refetch,
    error: error
      ? `Verfügbarkeit konnte nicht geprüft werden. (${error.message})`
      : undefined,
  };
}

interface IntervalValidity {
  isTooLong: boolean;
  isOverlappingWithBlockedSlot: boolean;
  valid: boolean;
}
function useSelectedIntervalValid(
  interval: [Date, Date],
  blockedSlots: { start: Date; end: Date }[]
): IntervalValidity {
  const [start, end] = interval;
  const isTooLong = differenceInMinutes(end, start) > MAX_BOOKING_LENGTH;
  const isOverlappingWithBlockedSlot = blockedSlots.some((blockedSlot) =>
    areIntervalsOverlapping(blockedSlot, { start, end })
  );

  return {
    isTooLong,
    isOverlappingWithBlockedSlot,
    valid: !isTooLong && !isOverlappingWithBlockedSlot,
  };
}

interface TimeRange {
  start: {
    hours: number;
    minutes: number;
  };
  end: {
    hours: number;
    minutes: number;
  };
}

const defaultTimeRange = {
  start: { hours: 18, minutes: 0 },
  end: { hours: 20, minutes: 0 },
};

function useLastSelectedTimeRange(selectedInterval: [Date, Date]) {
  const [lastSelectedTimeRange, setLastSelectedTimeRange] =
    useState<TimeRange>(defaultTimeRange);

  useEffect(() => {
    setLastSelectedTimeRange({
      start: {
        hours: getHours(selectedInterval[0]),
        minutes: getMinutes(selectedInterval[0]),
      },
      end: {
        hours: getHours(selectedInterval[1]),
        minutes: getMinutes(selectedInterval[1]),
      },
    });
  }, [selectedInterval]);

  return lastSelectedTimeRange;
}

export function CreateBooking() {
  const [selectedDay, setSelectedDay] = useState<Date>(startOfToday());
  const [selectedInterval, setSelectedInterval] = useState<[Date, Date]>([
    set(startOfToday(), defaultTimeRange.start),
    set(startOfToday(), defaultTimeRange.end),
  ]);
  const lastSelectedTimeRange = useLastSelectedTimeRange(selectedInterval);

  useEffect(() => {
    setSelectedInterval([
      set(selectedDay, lastSelectedTimeRange.start),
      set(selectedDay, lastSelectedTimeRange.end),
    ]);
  }, [selectedDay]);

  const {
    blockedSlots,
    loading: loadingBlockedSlots,
    error: blockedSlotsError,
    refetch
  } = useBlockedSlots(selectedDay);

  const validity = useSelectedIntervalValid(selectedInterval, blockedSlots);
  const [timerangeError, setTimerangeError] = useState(false);

  const [booking, setBooking] = useState<BookingFragment>();

  const reset = useMemo(
    () => () => {
      refetch()
      setSelectedDay(startOfToday());
      setBooking(undefined);
    },
    []
  );

  if (booking) return <Success booking={booking} onReset={reset} />;

  return (
    <>
      <div className="bg-white overflow-hidden shadow sm:rounded-lg mt-4">
        <div className="px-4 py-5 sm:p-6">
          <p className="mb-4">
            Wählen Sie zunächst den Tag aus, für den ein Meetingraum gebucht
            werden soll.
          </p>
          <div className="flex w-full">
            <Calendar
              className="flex-grow-0 flex-shrink-0"
              onChange={(date) => setSelectedDay(startOfDay(date))}
              value={selectedDay}
              minDate={new Date()}
              minDetail={"month"}
            />
            <div className="pl-4 flex-grow hidden sm:block">
              <InfoBlock
                loadingBlockedSlots={loadingBlockedSlots}
                error={blockedSlotsError}
                selectedInterval={selectedInterval}
                validity={validity}
                onSuccess={setBooking}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden shadow sm:rounded-lg mt-4">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-xl font-semibold">Zeitraum auswählen</h3>
          <p className="mt-2">
            Bitte wählen Sie den zu buchenden Zeitraum aus. Dieser darf zwei
            Stunden nicht überschreiten.{" "}
            {blockedSlots.length > 0 &&
              "Bereits ausgebuchte Zeiträume werden schraffiert dargestellt und können nicht gebucht werden."}
          </p>
          <TimelineRangeSlider
            valid={validity.valid && !timerangeError}
            selectedInterval={selectedInterval}
            day={selectedDay}
            onChange={setSelectedInterval}
            disabledIntervals={blockedSlots}
            onMove={({ error, interval: { start, end } }) =>
              setTimerangeError(
                error || differenceInMinutes(end, start) > MAX_BOOKING_LENGTH
              )
            }
          />
        </div>
      </div>
      <div className="bg-white overflow-hidden shadow mt-4 sm:hidden">
        <div className="px-4 py-5">
          <div className="">
            <InfoBlock
              loadingBlockedSlots={loadingBlockedSlots}
              error={blockedSlotsError}
              selectedInterval={selectedInterval}
              validity={validity}
              onSuccess={setBooking}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const Success: React.FC<{ booking: BookingFragment; onReset: () => void }> = ({
  booking,
  onReset,
}) => {
  const start = parseISO(booking.slot.start),
    end = parseISO(booking.slot.end);
  return (
    <div className="bg-white overflow-hidden shadow sm:rounded-lg sm:mx-3 my-4">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-xl mb-4 font-semibold">Buchung erfolgreich</h3>
        <p>
          Ihre Buchung für einen Meetingraum am{" "}
          {format(start, "eeee, dd.MM.yyyy", { locale: de })} von{" "}
          <b>{format(start, "HH:mm", { locale: de })} Uhr</b> bis{" "}
          <b>{format(end, "HH:mm", { locale: de })} Uhr</b> war erfolgreich.
        </p>
        <p>In Kürze erhalten Sie eine Bestätigungs-E-Mail.</p>
        <p className="mt-2">
          Sie können die Buchung jederzeit über den Reiter "Meine Buchungen"
          wieder stornieren.
        </p>
        <button
          type="button"
          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => onReset()}
        >
          Neue Buchung vornehmen
        </button>
      </div>
    </div>
  );
};

const TimelineRangeSlider: React.FC<{
  selectedInterval: [Date, Date];
  day: Date;
  onChange: (interval: [Date, Date]) => void;
  disabledIntervals: { start: Date; end: Date }[];
  onMove: (event: {
    error: boolean;
    interval: { start: Date; end: Date };
  }) => void;
  valid: boolean;
}> = ({
  selectedInterval,
  day,
  onChange,
  disabledIntervals,
  onMove,
  valid,
}) => {
  const timelineInterval: [Date, Date] = useMemo(
    () => [set(day, { hours: 7 }), endOfDay(day)],
    [day]
  );

  return (
    <TimeRange
      containerClassName={classNames(
        "px-0 pt-8 react_time_range_custom",
        valid
          ? "react_time_range_custom__valid"
          : "react_time_range_custom__error"
      )}
      error={!valid}
      selectedInterval={selectedInterval}
      timelineInterval={timelineInterval}
      onUpdateCallback={({
        error,
        time: [start, end],
      }: {
        error: boolean;
        time: [Date, Date];
      }) => {
        onMove({ error, interval: { start, end } });
      }}
      onChangeCallback={onChange}
      disabledIntervals={disabledIntervals}
    />
  );
};

const InfoBlock: React.FC<{
  loadingBlockedSlots: boolean;
  error?: string;
  selectedInterval: [Date, Date];
  validity: IntervalValidity;
  onSuccess: (booking: BookingFragment) => void;
}> = ({
  loadingBlockedSlots,
  selectedInterval,
  validity,
  onSuccess,
  error: outsideError,
}) => {
  const [createBookingMutation, { loading, error }] = useCreateBookingMutation({
    variables: { start: selectedInterval[0], end: selectedInterval[1] },
  });

  const createBooking = useMemo(
    () => () =>
      createBookingMutation()
        .then((rs) => {
          if (!rs.data) return;
          onSuccess(rs.data.createBooking);
        })
        .catch((err) => console.log(err)),
    [createBookingMutation]
  );
  return (
    <div className="h-full flex flex-col justify-between space-y-2">
      <div>
        <h3 className="text-xl font-semibold">Ihre Auswahl:</h3>
        <p className="text-lg my-2">
          {format(selectedInterval[0], "eeee, d. MMMM yyyy", { locale: de })}
          <br />
          {format(selectedInterval[0], "HH:mm", { locale: de })} Uhr -{" "}
          {format(selectedInterval[1], "HH:mm", { locale: de })} Uhr
        </p>
        {loadingBlockedSlots && <p>Prüfe Verfügbarkeit...</p>}
        {validity.valid && !outsideError && (
          <p className="text-green-700">Verfügbar.</p>
        )}
        {loading && <p>Buchung wird durchgeführt...</p>}
        {outsideError && <p className="text-red-dark">{outsideError}</p>}
        {validity.isTooLong && (
          <p className="text-red-dark">
            Der ausgewählte Zeitraum ist zu lang. Er darf zwei Stunden nicht
            überschreiten.
          </p>
        )}
        {validity.isOverlappingWithBlockedSlot && (
          <p className="text-red-dark">
            Der ausgewählte Zeitraum überschneidet sich mit einem bereits
            ausgebuchten Zeitraum.
          </p>
        )}
        {error && (
          <p className="text-red-dark">
            Fehler bei der Ausführung der Buchung: {error.message}
            <br />
            {error.networkError &&
              `(Netzwerkfehler - überprüfen Sie Ihre Internetverbindung)`}
          </p>
        )}
      </div>
      <div>
        <button
          type="button"
          className="w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          disabled={!validity.valid}
          onClick={() => {
            createBooking();
          }}
        >
          Buchung abschließen
        </button>
      </div>
    </div>
  );
};
