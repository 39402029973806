import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getPersistedLoginResult } from "./localStorage";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || "http://localhost:4001/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const loginResult = getPersistedLoginResult();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: loginResult?.token ? `Bearer ${loginResult.token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
