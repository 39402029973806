import React from "react";
import { UserWithoutBookingsFragment } from "../generated/graphql";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const Layout: React.FC<
  React.PropsWithChildren<{
    user?: UserWithoutBookingsFragment;
    logout: () => void;
  }>
> = ({ children, user, logout }) => {
  return (
    <>
      <div className="fwag-bg fixed z-0 bottom-0 w-full h-screen"></div>
      <div className="relative z-10 flex flex-col h-screen justify-between text-gray-800">
        <Header user={user} logout={logout}/>
        <main className="container mx-auto max-w-screen-sm py-4 mb-auto">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
